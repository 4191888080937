/* eslint-disable @typescript-eslint/ban-ts-comment */
export const base64Encode = (...parts: any[]) =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
      const offset = reader.result.indexOf(',') + 1;
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      resolve(reader.result.slice(offset));
    };
    reader.readAsDataURL(new Blob(parts));
  });
