'use client';
import { Suspense, lazy } from 'react';

import { Loader } from '@mantine/core';

const PosterProjectPreview = lazy(() => import('~/features/poster/ProjectPreview'));

const PosterPreviewView = () => {
  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1440px' }}>
        <PosterProjectPreview tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default PosterPreviewView;
