import { createTheme } from '@mantine/core';
import type { MantineTheme } from '@mantine/core';
// import { themeToVars } from '@mantine/vanilla-extract';

import { PartialObjectDeep } from 'type-fest/source/partial-deep';

export const theme = createTheme({
  fontSmoothing: true,
  fontFamily: 'BIZ UDGothic, sans-serif',
  fontFamilyMonospace: 'BIZ UDGothic, monospace',
  headings: { fontFamily: 'BIZ UDGothic, sans-serif' },
  primaryColor: 'indigo',
  primaryShade: 6,
  fontSizes: {
    xs: '9pt', //12, // 9pt
    sm: '10.5pt', //14, // 10.5pt
    md: '12pt', //16, // 12pt
    lg: '15pt', //20, // 15pt
    xl: '18pt', //24, // 18pt
  },
  breakpoints: {
    xs: '32em', //512 /* 32em */,
    sm: '48em', //768 /* 48em */,
    md: '64em', //1024 /* 64em */,
    lg: '80em', //1280 /* 80em */,
    xl: '96em', //1536 /* 96em */,
  },
});

// CSS variables object, can be access in *.css.ts files
// export const vars = themeToVars(theme);
