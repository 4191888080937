import axios from 'axios';

import { buildWebStorage, setupCache, type AxiosCacheInstance } from 'axios-cache-interceptor';

export const axiosInstance = setupCache(
  axios.create({
    timeout: 60 * 60 * 1000, // 60 minuteså
    adapter: 'fetch',
    // https: {
    //   checkServerIdentity: (host, cert) => {
    //     // Customize certificate verification here, returning an error if the
    //     // certificate is invalid or should not be trusted.
    //   }
    // },
    validateStatus: status => {
      // Only reject responses with status codes outside the 2xx range
      return status >= 200 && status < 300;
    },
  }),
  {
    storage: buildWebStorage(sessionStorage, 'axios-cache:'),
  }
);
