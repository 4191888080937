import { useEffect, useRef } from 'react';

import { clsx } from 'clsx';

import styles from './Clock.module.css';

const Clock = (props: { time: string | null }) => {
  const { time: t } = props;
  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (t == null || t.length === 0 || t.indexOf(':') < 0) {
      return;
    }
    const time = t?.split(':');
    // Get time
    const h = parseInt(time[0]),
      m = parseInt(time[1]),
      s = 0;

    // Get angles
    const degHour = (h % 12) * (360 / 12) + m * (360 / 12 / 60);
    const degMin = m * (360 / 60) + s * (360 / 60 / 60);
    // const degSec = s * (360 / 60);

    // Set angles to CSS custom property
    if (ref.current != null) {
      const elemStyle = ref.current.style;
      elemStyle.setProperty('--degHour', `${degHour}deg`);
      elemStyle.setProperty('--degMin', `${degMin}deg`);
    }
  }, [t, ref]);

  return (
    <div ref={ref} className={styles.clock}>
      <div
        className={clsx(styles.hHand, {
          display: t == null || t.length === 0 || t.indexOf(':') < 0 ? 'none' : 'block',
        })}></div>
      <div
        className={clsx(styles.mHand, {
          display: t == null || t.length === 0 || t.indexOf(':') < 0 ? 'none' : 'block',
        })}></div>
      <div
        className={clsx(styles.center, {
          display: t == null || t.length === 0 || t.indexOf(':') < 0 ? 'none' : 'block',
        })}></div>
    </div>
  );
};

export default Clock;
