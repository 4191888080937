import { useCallback } from 'react';

import { atom, useRecoilState, useRecoilValue } from 'recoil';

import { ISettings, ITenantSettings, IUserSettings } from '~/types/application';

export type SettingsState = {
  tenantSettings?: ITenantSettings;
  userSettings?: IUserSettings;
};

const settingsRecoilState = atom<SettingsState>({
  key: 'SettingsState',
  default: {
    tenantSettings: undefined,
    userSettings: undefined,
  },
});

export const useSettings = () => useRecoilValue(settingsRecoilState);

export const useSettingsMutators = () => {
  const [state, setState] = useRecoilState(settingsRecoilState);
  const settings = useSettings();

  const setTenantSettings = useCallback(
    (tenantSettings?: ITenantSettings) =>
      setState({
        tenantSettings,
        userSettings: settings.userSettings,
      }),
    [setState, state]
  );
  const setUserSettings = useCallback(
    (userSettings?: IUserSettings) =>
      setState({
        tenantSettings: settings.tenantSettings,
        userSettings,
      }),
    [setState, state]
  );
  const setSettings = useCallback(
    (settings?: ISettings) =>
      setState({
        tenantSettings: settings?.tenant,
        userSettings: settings?.user,
      }),
    [setState, state]
  );
  const resetSettings = useCallback(
    () =>
      setState({
        tenantSettings: undefined,
        userSettings: undefined,
      }),
    [setState, state]
  );

  return { setTenantSettings, setUserSettings, setSettings, resetSettings };
};
