import { memo, SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 742 742" {...props}>
    <path
      fill="#4273c6"
      d="M25 415V76c0-31 21-52 52-52h593c31 0 52 21 52 52v338a4197 4197 0 0 1-155 1l70-41-71-41v82l-28-1c5-18 6-37 4-55-4-48-25-88-62-118-39-32-84-45-133-37-64 10-109 46-135 106-14 34-16 69-7 105H25m152-17v-67l-71 42 71 41v-16m222-243-28-47-41 71h83l-14-24m-176 42-38-10 21 78 57-57-40-11m324 39 13-48-78 20 57 57 8-29Z"
    />
    <path
      fill="#ade7ff"
      d="M538 415h183l1 259c0 24-20 43-42 46l-15 1H76c-29 0-51-23-51-51V415h513m-303 65h223c9 0 16-5 18-12 3-8 2-18-5-22-4-3-10-5-15-5H116c-10 1-18 9-18 19 0 11 8 20 20 20h117m48 40c-4 14 5 26 20 26a534861 534861 0 0 0 295-1c10-2 16-12 15-22s-10-17-22-17H304c-10 0-17 4-21 14m149 51H266c-5 0-10 2-14 5-7 4-9 13-6 21s10 13 19 13h246c5 0 10-2 14-5 6-4 9-13 6-21s-10-13-19-13h-80m68-118c-4 15 5 27 20 27h108c12 0 21-8 21-20 0-11-9-19-20-19H520c-9 0-15 4-20 12m-303 93h41c12 0 20-9 20-20s-9-19-20-19h-72c-11 0-20 8-20 19s8 20 20 20h31Z"
    />
    <path
      fill="#ef7b31"
      d="m538 414-2 1H206c-10-36-8-71 6-105 26-60 71-96 135-106 49-8 94 5 133 37 37 30 58 70 62 118 2 18 1 37-4 55Z"
    />
    <path fill="#ed7b32" d="M177 399v15l-71-41 71-42v68Zm222-243 14 23h-83l41-71 28 48Z" />
    <path fill="#ed7b33" d="m223 197 40 11-57 57-21-78 38 10Zm324 40-8 28-57-57 78-20-13 49Z" />
    <path fill="#ed7b32" d="M567 415h-1v-82l71 41-70 41Z" />
    <path
      fill="#f7b584"
      d="M234 480H118c-12 0-20-9-20-20 0-10 8-18 18-19h340c5 0 11 2 15 5 7 4 8 14 5 22-2 7-9 12-18 12H234Zm49 39c4-9 11-13 21-13h287c12 0 21 7 22 17a20 20 0 0 1-21 23H303c-15 0-24-12-20-27Zm149 52h80c9 0 16 5 19 13s0 17-6 21c-4 3-9 5-14 5H265c-9 0-16-5-19-13s-1-17 6-21c4-3 9-5 14-5h166Zm69-118c4-8 10-12 19-12h109c11 0 20 8 20 19 0 12-9 20-21 20H520c-15 0-24-12-19-27Z"
    />
    <path fill="#f7b585" d="M196 546h-30c-12 0-20-9-20-20s9-19 20-19h72c11 0 20 8 20 19s-8 20-20 20h-42Z" />
  </svg>
);
const Memo = memo(SvgComponent);
export { Memo as SunsetSquare };
