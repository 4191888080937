'use client';

import { Suspense, lazy } from 'react';

import { Loader } from '@mantine/core';

const UserMaintenance = lazy(() => import('~/features/aspi/Maintenance/User'));

const UserMaintenanceView = () => {
  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
        <UserMaintenance tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default UserMaintenanceView;
