import { HTMLAttributes } from 'react';

import DailyPortlet from './DailyPortlet';
import MonthlyPortlet from './MonthlyPortlet';
import OperationLogPortlet from './OperationLogPortlet';
import WorkTodayPortlet from './WorkTodayPortlet';

const Dashboard = (props: { tenantId: string } & HTMLAttributes<HTMLDivElement>) => {
  const { className, tenantId } = props;

  if (tenantId == null || tenantId.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className={className}>
        <div className="mt-4 flex min-w-fit flex-row">
          <DailyPortlet tenantId={tenantId} className="basis-5/12 p-2" />
          <MonthlyPortlet tenantId={tenantId} className="basis-7/12 p-2" />
        </div>
        <div className="mt-2 flex flex-row">
          <WorkTodayPortlet tenantId={tenantId} className="basis-5/12 p-2" />
          <OperationLogPortlet tenantId={tenantId} className="basis-7/12 p-2" />
        </div>
      </div>
      {/*<embed src="/poster/public/marking" width="0" height="0" />*/}
    </>
  );
};

export default Dashboard;
