'use client';
import { lazy, Suspense } from 'react';

import { Loader } from '@mantine/core';

const PosterTemplateList = lazy(() => import('~/features/poster/TemplateList'));

const PosterTemplateView = () => {
  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1440px' }}>
        <PosterTemplateList tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default PosterTemplateView;
