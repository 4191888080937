'use client';
import { lazy, Suspense, useEffect } from 'react';

import { Loader } from '@mantine/core';

import ApplicationLog from '~/utils/ApplicationLog';

import { useAxios } from '~/components/common/providers';

import { watchdog } from '~/workers/watchdog/watchdog-client';

const AspiScheduleTable = lazy(() => import('~/components/ui/aspi/AspiPlanningComponent/AspiScheduleTable'));

const WorkdayPlanningView = () => {
  const axios = useAxios();

  useEffect(() => {
    void ApplicationLog(axios, 'workdayPlanning', 'enter', '');
    watchdog('workdayPlanning', '稼働予定管理');

    return () => {
      void ApplicationLog(axios, 'workdayPlanning', 'enter', '');
    };
  }, []);

  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
        <AspiScheduleTable tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default WorkdayPlanningView;
