import { ILoggingConfig } from '~/utils/logging/LoggerFactory';

const { loggerConfig } = window;

declare global {
  interface Window {
    loggerConfig: ILoggingConfig;
  }
}

export const configLogger = () => {
  window.loggerConfig = {
    isTraceEnabled: true,
    isDebugEnabled: true,
    isInfoEnabled: true,
    isErrorEnabled: true,
    isWarnEnabled: true,
  };
};
