import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit={10}
    clipRule="evenodd"
    viewBox="0 0 556 612"
    fill="currentColor"
    {...props}>
    <path d="M205 532V333c0-15 12-29 27-30l19-1h227c14 1 25 5 31 18l4 17v184c0 14-5 26-18 32l-17 4H239c-18 0-28-8-34-25m69-99-21-21v90c0 5 2 6 6 6h199l5-1v-94h-1l-4 4-69 70c-18 18-43 18-61 0l-54-54m137-81H263l95 94 94-94h-41Z" />
    <path d="m434 275-49 1V118c0-6-1-13-5-18-5-7-12-10-21-10H115l-9 1c-10 1-18 9-19 19l-1 8v336c0 6 1 12 5 18 5 7 13 10 21 10h67v48l-66 1c-34-1-62-19-71-50-3-8-4-17-4-26l-1-338c0-36 22-65 56-73l20-3h245c37 0 65 22 73 56 2 6 3 13 3 20v158Z" />
    <path d="M138 180c-9-7-13-15-11-26 2-10 8-16 17-18l11-2h166c15 0 24 6 27 18 5 15-5 29-20 31l-8 1-165-1-17-3Zm78 52 54 1c13 1 22 11 22 24 1 13-9 23-21 24l-6 1h-94c-14 0-23-7-27-19-3-14 6-29 21-30l51-1Zm-83 142c-7-8-8-17-4-26s11-14 21-14h29v48h-33c-5-1-9-5-13-8Z" />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
