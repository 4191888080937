import { memo, SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 742 742" {...props}>
    <path
      fill="#315294"
      d="M365 722H73c-26 0-45-16-49-41V74c0-29 20-49 49-49h600c29 0 49 20 49 49v598c0 30-20 50-50 50H365M190 456c33 79 111 119 175 117-61-48-94-111-94-189s33-141 94-190c-11 1-22 1-32 3-57 11-101 41-131 91-31 54-34 110-12 168m349-160 22-17h-55c-3 0-5-1-6-4l-15-50-2-4-17 53c-1 4-3 5-7 5h-54l41 31c7 6 7 6 4 15l-15 50 4-3 38-30c5-3 7-3 11 0l39 30 4 2-17-54c-1-3-1-5 2-7l23-17Z"
    />
    <path
      fill="#fefe01"
      d="M190 456c-22-58-19-114 12-168 30-50 74-80 131-91 10-2 21-2 32-3-61 49-94 112-94 190s33 141 94 189c-64 2-142-38-175-117Z"
    />
    <path
      fill="#fcfd02"
      d="m538 296-22 17c-3 2-3 4-2 7l17 54-4-2-39-30c-4-3-6-3-11 0l-38 30-4 3 15-50c3-9 3-9-4-15l-41-31h54c4 0 6-1 7-5l17-53 2 4 15 50c1 3 3 4 6 4h55l-23 17Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export { Memo as MidnightSquare };
