'use client';

import { Suspense, lazy } from 'react';

import { Loader } from '@mantine/core';

const YearlyContract = lazy(() => import('src/features/aspi/YearlyContract'));

const YearlyContractView = () => {
  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '1440px', height: '100%', minWidth: '1440px' }}>
        <YearlyContract tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default YearlyContractView;
