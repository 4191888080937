'use client';

import { Suspense, lazy } from 'react';

import { Loader } from '@mantine/core';

const SectionMaintenance = lazy(() => import('~/features/aspi/Maintenance/Section'));

const SectionMaintenanceView = () => {
  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
        <SectionMaintenance tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default SectionMaintenanceView;
