// eslint-disable-next-line import/namespace
import { Outlet } from 'react-router-dom';

import WebVitals from '~/features/WebVitals';

const LayoutBlank = () => (
  <>
    <WebVitals />
    <Outlet />
  </>
);

export default LayoutBlank;
