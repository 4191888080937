import { memo, SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 742 742" {...props}>
    <path
      fill="#aee7ff"
      d="M451 24h224c22 0 43 17 46 39l1 11v600c0 26-21 47-48 47H72c-21 0-40-15-45-35l-2-16V72c0-27 22-48 48-48h378m1 501a171 171 0 0 0 1-304c-32-17-67-24-104-18-35 5-66 19-92 43-28 26-46 58-52 96a168 168 0 0 0 57 161c30 26 66 39 105 41 30 1 58-5 85-19m37 7-7 8 80 21-22-79-7 6-44 44m-5-322 56 57 22-80-7 1-36 9-34 10c-2 0-4 0-1 3M243 518l-37-37-21 80 80-21-22-22m-41-271 5 17 57-58-78-20 16 61m-49 98-47 26 72 42v-83l-25 15m209 222h-30l41 71 41-71h-52m-2-389h54l-41-72-41 72h28m253 180-45-26v82l72-41-27-15Z"
    />
    <path
      fill="#ff7373"
      d="M452 525c-27 14-55 20-85 19-39-2-75-15-105-41a169 169 0 0 1-57-161c6-38 24-70 52-96 26-24 57-38 92-43 37-6 72 1 104 18 28 14 50 35 66 61a169 169 0 0 1-67 243Z"
    />
    <path fill="#fc7677" d="m490 532 43-44 7-6 22 79-80-21 8-8Zm-6-322c-3-3-1-3 1-3l34-10 36-9 7-1-22 80-56-57Z" />
    <path fill="#fc7676" d="m243 518 22 22-80 21 21-80 37 37Z" />
    <path fill="#fb7677" d="m202 247-16-61 78 20-57 58-5-17Z" />
    <path fill="#fb7676" d="m153 344 25-14v83l-72-42 47-27Z" />
    <path fill="#fc7575" d="M362 567h52l-41 71-41-71h30Z" />
    <path fill="#fb7676" d="M359 178h-27l41-72 41 72h-55Zm255 180 26 15-72 41v-82l46 26Z" />
  </svg>
);
const Memo = memo(SvgComponent);
export { Memo as DaySquare };
