import { publish } from '~/utils/event';

import type { LoggingMessage, MessageWrapper } from '~/workers/session/message';

const { loggerConfig } = window;

declare global {
  interface Window {
    loggerConfig: ILoggingConfig;
  }
}

export type Loggable = string | (() => string);
export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error';

export interface ILogger {
  trace: (message: Loggable) => void;
  debug: (message: Loggable) => void;
  info: (message: Loggable) => void;
  warn: (message: Loggable) => void;
  error: (message: Loggable) => void;
}

export interface ILoggingConfig {
  isTraceEnabled: boolean;
  isDebugEnabled: boolean;
  isInfoEnabled: boolean;
  isWarnEnabled: boolean;
  isErrorEnabled: boolean;
}

class Logger implements ILogger {
  private config: ILoggingConfig;

  constructor(config: ILoggingConfig) {
    this.config = config;
  }

  logger: (level: LogLevel, message: Loggable) => void = () => {};

  trace(message: Loggable): void {
    if (this.config.isTraceEnabled) this.logger('trace', message);
  }

  debug(message: Loggable): void {
    if (this.config.isDebugEnabled) this.logger('debug', message);
  }

  info(message: Loggable): void {
    if (this.config.isInfoEnabled) this.logger('info', message);
  }

  warn(message: Loggable): void {
    if (this.config.isWarnEnabled) this.logger('warn', message);
  }

  error(message: Loggable): void {
    if (this.config.isErrorEnabled) this.logger('error', message);
  }
}

export const getLogger = (category: string): ILogger => {
  const logger = new Logger(window.loggerConfig);
  logger.logger = (level: LogLevel, msg: Loggable) => {
    const message = typeof msg === 'string' ? msg : msg();
    const log = { category, level, message } as LoggingMessage;
    console.dir(log);
    publish<MessageWrapper<LoggingMessage>>('send', { messageType: 'logging', message: log } as MessageWrapper<any>);
  };

  return logger;
};

const LoggerFactory = { getLogger };
export default LoggerFactory;
