import { forwardRef, memo, useRef, useState } from 'react';

import PickerSelect, { IPickerSelectItem } from './PickerSelect';
import ZoneSelectorPanel from './ZoneSelectorPanel';

type TimeSelectListProps = {
  hour: number;
  minute: number;
  step: number;
  hourArray: number[];
  minuteArray: number[];
  min: number;
  max: number;
  selectFocusOn: string | null;
  onItemChange: (type: string, value: IPickerSelectItem) => void;
  onKeyDown: (currentType: string, e: React.KeyboardEvent<HTMLElement>) => void;
  isEnd: boolean;
  fromValue?: string | null;
};

// eslint-disable-next-line react/display-name
const HourSelect = forwardRef<HTMLDivElement, TimeSelectListProps>((props, ref) => {
  const { hour, minute, step, min, max, hourArray, selectFocusOn, onItemChange, onKeyDown, isEnd } = props;

  // 下限09:45の時に、09hを選択できるようにする
  const mi = Math.floor(min / (60 / step)) * (60 / step);

  const hours = hourArray.map(
    h =>
      ({
        disabled: (min >= 0 && min < max && (h * 60) / step < mi) || (max >= 0 && max > min && (h * 60) / step > max),
        value: <div>{h.toString(10).padStart(2, '0')}</div>,
        label: h.toString(10).padStart(2, '0'),
        number: h,
      }) as IPickerSelectItem
  );

  return (
    <div className="flex flex-col items-center justify-start">
      <div className="text-[12pt]">時</div>
      <PickerSelect
        ref={ref}
        items={hours}
        selectedIndex={hours.findIndex(f => f.number == hour)}
        type="hour"
        label="hour"
        focused={selectFocusOn === 'hour'}
        onSelect={onItemChange}
        onKeyDown={e => onKeyDown('hour', e)}
      />
    </div>
  );
});

// eslint-disable-next-line react/display-name
const MinuteSelect = forwardRef<HTMLDivElement, TimeSelectListProps>((props, ref) => {
  const { hour, minute, step, min, max, minuteArray, selectFocusOn, onItemChange, onKeyDown, isEnd } = props;
  const minutes = minuteArray.map(
    m =>
      ({
        disabled:
          (min >= 0 && min < max && (hour * 60 + m) / step + 1 < min) ||
          (max >= 0 && max > min && (hour * 60 + m) / step > max),
        value: <div>{m.toString(10).padStart(2, '0')}</div>,
        label: m.toString(10).padStart(2, '0'),
        number: m,
      }) as IPickerSelectItem
  );

  return (
    <div className="flex flex-col items-center justify-start">
      <div className="text-[12pt]">分</div>
      <PickerSelect
        ref={ref}
        items={minutes}
        selectedIndex={minutes.findIndex(f => f.number == minute)}
        type="minute"
        label="minute"
        focused={selectFocusOn === 'minute'}
        onSelect={onItemChange}
        onKeyDown={e => onKeyDown('minute', e)}
      />
    </div>
  );
});

type TimePickerPanelProps = {
  offset?: number;
  step?: number;
  block?: number;
  hourArray: number[];
  minuteArray: number[];
  min?: number;
  max?: number;
  onChange?: (block: number) => void;
  isEnd: boolean;
  fromValue?: string | null;
  workStartBlock: number;
  noonStartBlock: number;
};
const TimePickerPanel = memo((props: TimePickerPanelProps) => {
  const {
    offset = 0,
    step = 15,
    block: _block,
    min: _min = -1,
    max: _max = (48 * 3600) / (step * 60),
    onChange,
    hourArray,
    minuteArray,
    isEnd,
    fromValue,
    workStartBlock,
    noonStartBlock,
  } = props;
  const [selectFocusOn, setSelectFocusOn] = useState<string | null>(null);

  const hourListRef = useRef<HTMLDivElement>(null!);
  const minuteListRef = useRef<HTMLDivElement>(null!);

  const [block, setBlock] = useState(_block);
  const hour = block != null && block >= 0 && (_block ?? -1) >= 0 ? Math.trunc((block * step) / 60) : -1;
  const minute = block != null && block >= 0 && (_block ?? -1) >= 0 ? ((block * step * 60) % 3600) / 60 : -1;

  const handleChange = (h: number, m: number) => {
    const hh = h >= 0 ? h : 0;
    const mm = m >= 0 ? m : 0;
    const v = (hh * 60) / step + mm / step;

    // 下限09:45の時に、09hを選択できるようにする
    const _mi = Math.floor(_min / (60 / step)) * (60 / step);

    // 上限16:15の時に、45mを選択できるようにする
    const _ma = Math.ceil(_max / (60 / step)) * (60 / step);

    if (offset <= v) {
      setBlock(v < _min - 1 ? _min - 1 : v >= _max ? _max : v);
      onChange && onChange(v < _min - 1 ? _min - 1 : v >= _max ? _max : v);
    }
  };

  const onItemChange = (type: string, item: IPickerSelectItem) => {
    if (type === 'hour') {
      handleChange(item.number, minute);
    } else if (type === 'minute') {
      handleChange(hour, item.number);
    }
  };

  const getColumns = () => {
    // get list of enabled columns (e.g. ['hour', 'minute', 'ampm'])
    return [
      { val: 'hour', enabled: true },
      { val: 'minute', enabled: true },
    ]
      .filter(({ enabled }) => enabled)
      .map(({ val }) => val);
  };

  const changeFocusTo = (currentSelectType: string, offset: number) => {
    const columns = getColumns();

    const currentIndex = columns.indexOf(currentSelectType);
    let newIndex = currentIndex + offset;

    // bounds + wrap
    if (newIndex < 0) {
      newIndex = columns.length - 1;
    } else if (newIndex >= columns.length) {
      newIndex = 0;
    }

    const newFocusOn = columns[newIndex];
    setSelectFocusOn(newFocusOn);
  };

  const handleKeyDown = (currentType: string, e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'ArrowRight') {
      // right arrow
      changeFocusTo(currentType, 1);
      e.preventDefault();
      e.stopPropagation();
    } else if (e.code === 'ArrowLeft') {
      // left arrow
      changeFocusTo(currentType, -1);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className="flex flex-row items-start justify-start">
      <ZoneSelectorPanel
        ref={hourListRef}
        offset={offset}
        step={step}
        workStartBlock={workStartBlock}
        noonStartBlock={noonStartBlock}
        fromValue={fromValue}
      />
      <div className="mt-[3.5rem] h-[14rem] border-r border-r-gray-6" />
      <HourSelect
        ref={hourListRef}
        hour={hour}
        minute={minute}
        step={step}
        hourArray={hourArray}
        minuteArray={minuteArray}
        min={_min}
        max={_max}
        selectFocusOn={selectFocusOn}
        isEnd={isEnd}
        onItemChange={onItemChange}
        onKeyDown={handleKeyDown}
      />
      <div className="mt-[3.5rem] h-[14rem] border-r border-r-gray-6" />
      <MinuteSelect
        ref={minuteListRef}
        hour={hour}
        minute={minute}
        step={step}
        hourArray={hourArray}
        minuteArray={minuteArray}
        min={_min}
        max={_max}
        selectFocusOn={selectFocusOn}
        isEnd={isEnd}
        onItemChange={onItemChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
});
TimePickerPanel.displayName = 'TimePickerPanel';
export default TimePickerPanel;
