const subscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
  window.addEventListener(eventName, listener);
};

const unsubscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
  window.removeEventListener(eventName, listener);
};

const publish = <T>(eventName: string, data: T | undefined = undefined) => {
  const event = new CustomEvent(eventName, { detail: data });
  window.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };
