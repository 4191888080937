import { forwardRef, KeyboardEventHandler, MouseEventHandler, ReactNode, RefObject, useEffect, useRef } from 'react';

import { clsx } from 'clsx';

import styles from './PickerSelect.module.css';

const scrollTo = (element: HTMLElement, to: number, duration: number) => {
  // jump to target if duration zero
  if (duration <= 0) {
    element.scrollTop = to;

    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  element.scrollTop += perTick;
  if (element.scrollTop === to) return;
  scrollTo(element, to, duration - 10);
};

export interface IPickerSelectItem {
  disabled: boolean;
  value: ReactNode;
  label: string;
  number: number;
}

type SelectProps = {
  items?: Array<IPickerSelectItem>;
  selectedIndex: number;
  type: string;
  label?: string;
  onSelect?: (type: string, value: IPickerSelectItem) => void;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
  focused?: boolean;
};

// eslint-disable-next-line react/display-name
const PickerSelect = forwardRef<HTMLDivElement, SelectProps>((props, selectRef) => {
  const {
    items = [],
    selectedIndex,
    type = '',
    label = '',
    onSelect = () => {},
    onMouseEnter = () => {},
    onKeyDown = () => {},
    focused = false,
  } = props;

  useEffect(() => {
    if (selectRef != null) {
      const container = (selectRef as RefObject<HTMLElement>).current;
      if (container != null) {
        container.onscroll = (e: Event) => {
          const elem = e.currentTarget as HTMLElement;
          console.log(elem.scrollTop);
          Array.from(elem.firstElementChild!.children).forEach(e => {
            const item = e as HTMLElement;
            const sticky = item.offsetTop;
            if (elem.scrollTop > sticky - 10) {
              item.style.fontSize = '9pt';
              // item.style.opacity = '0.5';
            } else if (elem.scrollTop + elem.clientHeight - 20 * 1.4 < sticky) {
              item.style.fontSize = '9pt';
              // item.style.opacity = '0.5';
            } else {
              item.style.fontSize = '15pt';
              // item.style.opacity = '1';
            }
          });
        };
      }
    }
  }, [selectRef]);

  // const selectRef = useRef<HTMLDivElement>(null!)
  const listRef = useRef<HTMLUListElement>(null!);

  const scrollToSelected = (duration: number) => {
    // move to selected item
    const list = listRef.current;
    if (!list) {
      return;
    }
    let index = selectedIndex;
    if (index < 0 || Number.isNaN(index)) {
      index = 0;
    }
    const topOption = list.children[index] as HTMLElement;
    const to = topOption.offsetTop;
    scrollTo((selectRef as RefObject<HTMLDivElement>).current!, to, duration);
  };

  const changeFocusBy = (offset: number) => {
    if (selectedIndex < 0 || Number.isNaN(selectedIndex)) {
      return;
    }

    // get new element index
    let index = selectedIndex + offset;
    if (index < 0) {
      index = items.length - 1;
    } else if (index >= items.length) {
      index = 0;
    }

    // get new value
    const selectedOption = items[index];
    onSelect(type, selectedOption);

    // get new ref
    const list = listRef.current;
    if (!list) {
      return;
    }
    const optionRef = list.children[index] as HTMLElement;
    optionRef.focus();
  };

  //  componentDidMount()
  useEffect(() => {
    scrollToSelected(0);
  }, []);

  // componentDidUpdate(prevProps)
  useEffect(() => {
    // smooth scroll to selected option
    scrollToSelected(120);
  }, [selectedIndex]);

  useEffect(() => {
    // focus on selectedIndex
    changeFocusBy(0);
  }, [focused]);

  const getOptions = () => {
    return items.map((item, index) => {
      const selected = selectedIndex === index;
      const cls = clsx({
        [styles.selected]: selectedIndex >= 0 && !Number.isNaN(selectedIndex) && selected,
        [styles.disabled]: item.disabled ?? false,
      });
      const onClick = item.disabled ? undefined : () => onSelect(type, item);

      const onKeyDown: KeyboardEventHandler<HTMLLIElement> = e => {
        if (e.code === 'Enter' || e.code === 'Escape') {
          // enter or space
          if (onClick != null) onClick();
          e.preventDefault();
          e.stopPropagation();
        }
      };

      return (
        <li
          key={index}
          role="radio"
          className={cls}
          tabIndex={0}
          aria-disabled={item.disabled}
          aria-checked={selected}
          aria-label={item.label}
          onClick={onClick}
          onKeyDown={onKeyDown}>
          {item.value}
        </li>
      );
    });
  };

  const handleKeyDown: KeyboardEventHandler<HTMLElement> = e => {
    if (e.code === 'ArrowDown') {
      // down arrow
      changeFocusBy(1);
      e.preventDefault();
      e.stopPropagation();
    } else if (e.code === 'ArrowUp') {
      // up arrow
      changeFocusBy(-1);
      e.preventDefault();
      e.stopPropagation();
    }
    // pass keydown to parent
    onKeyDown(e);
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div className="h-[240px] overflow-y-hidden">
      <div
        className={clsx('relative top-0 h-6 text-[12pt] font-bold', styles.selector, { hidden: items.length < 8 })}
        onClick={() => {
          const container = (selectRef as RefObject<HTMLElement>).current;
          if (container != null) {
            const height = container.firstElementChild!.firstElementChild!.clientHeight;
            container.scrollTop -= height;
          }
        }}
        onMouseDown={e => {
          const elem = e.currentTarget as HTMLElement;
          const pstart = Date.now();
          const container = (selectRef as RefObject<HTMLElement>).current;
          if (container != null) {
            const height = container.firstElementChild!.firstElementChild!.clientHeight;
            const htimer = setInterval(() => {
              if (Date.now() - pstart > 500) {
                container.scrollTop -= height;
              }
            }, 100);
            elem.onmouseup = () => {
              clearInterval(htimer);
            };
          }
        }}>
        ▲
      </div>
      <div
        ref={selectRef}
        style={{ marginTop: items.length > 7 ? '0' : '15pt' }}
        className={clsx(styles.select, styles.column)}
        onKeyDown={handleKeyDown}
        onMouseEnter={onMouseEnter}>
        <ul ref={listRef} role="radiogroup" aria-label={label}>
          <li></li>
          {getOptions()}
          <li></li>
        </ul>
      </div>
      <div
        className={clsx('relative -top-12 h-6 text-[12pt] font-bold', styles.selector, { hidden: items.length < 8 })}
        onClick={() => {
          const container = (selectRef as RefObject<HTMLElement>).current;
          if (container != null) {
            const height = container.firstElementChild!.firstElementChild!.clientHeight;
            container.scrollTop += height;
          }
        }}
        onMouseDown={e => {
          const elem = e.currentTarget as HTMLElement;
          const pstart = Date.now();
          const container = (selectRef as RefObject<HTMLElement>).current;
          if (container != null) {
            const height = container.firstElementChild!.firstElementChild!.clientHeight;
            const htimer = setInterval(() => {
              if (Date.now() - pstart > 500) {
                container.scrollTop += height;
              }
            }, 100);
            elem.onmouseup = () => {
              clearInterval(htimer);
            };
          }
        }}>
        ▼
      </div>
    </div>
  );
});

export default PickerSelect;
