'use client';
const SettingsView = () => {
  return (
    <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
      <iframe width="100%" height="100%" src="/config/index.html"></iframe>
    </div>
  );
};

export default SettingsView;
