// https://blog.jxck.io/entries/2020-01-18/reporting.html
// https://github.com/Jxck/jxck.io/blob/main/www.jxck.io/assets/js/main.js

if (window.ReportingObserver) {
  console.log('ReportingObserver');
  const instanceId = window.instanceId ?? '';
  const clientId = localStorage.getItem('client-id') ?? '';
  const fingerprint = localStorage.getItem('fingerprint') ?? '';
  const tenantId = window.tenantId ?? '';
  const userId = window.userId ?? '';

  const observer = new ReportingObserver(
    (reports, _observer) => {
      console.log(reports);
      const URL = '/network/reports/beacon-endpoint';
      for (const report of reports) {
        const envelope = {
          instanceId,
          clientId,
          fingerprint,
          tenantId,
          userId,
          ...report,
        };

        const blob = new Blob([JSON.stringify(envelope)], {
          type: 'application/reports+json',
        });

        (navigator.sendBeacon && navigator.sendBeacon(URL, blob)) ||
          fetch(URL, { body: blob, method: 'POST', keepalive: true });
      }
    },
    { buffered: true }
  );
  observer.observe();
}

if (window.PerformanceObserver) {
  console.log(PerformanceObserver.supportedEntryTypes);

  // ['element', 'event', 'first-input', 'largest-contentful-paint', 'layout-shift', 'long-animation-frame', 'longtask', 'mark', 'measure', 'navigation', 'paint', 'resource', 'visibility-state']
  const performance = {} as any;

  const observer = new PerformanceObserver(list => {
    for (const entry of list.getEntries()) {
      switch (entry.entryType) {
        case 'navigation':
          // @ts-ignore
          const connect = entry.connectEnd; // TCP establish
          // @ts-ignore
          const request = entry.responseStart - entry.requestStart; // request time
          // @ts-ignore
          const response = entry.responseEnd - entry.responseStart; // response time
          // @ts-ignore
          const dom = entry.domComplete - entry.responseEnd; // dom time
          // @ts-ignore
          const load = entry.loadEventEnd - entry.loadEventStart; // load
          const duration = entry.duration; // duration
          performance.navigation = {
            '0connect': connect,
            '1request': request,
            '2response': response,
            '3dom': dom,
            '4load': load,
            '5duration': duration,
          };
          break;
        case 'largest-contentful-paint':
          performance.lcp = performance.lcp || [];

          const lcp = {
            // @ts-ignore
            elem: entry.element.nodeName,
            // @ts-ignore
            time: entry.renderTime || entry.loadTime,
          };
          performance.lcp.push(lcp);
          break;
        case 'paint':
          performance[entry.name] = entry.startTime;
          break;
        case 'resource':
          performance.resource = performance.resource || {};
          performance.resource[entry.name] = entry.duration;
          break;
        case 'layout-shift':
          performance.layoutshift = performance.layoutshift || 0;
          // @ts-ignore
          performance.layoutshift += entry.value;
          break;
        case 'longtask':
          performance.longtask = performance.longtask || 0;
          performance.longtask += entry.duration;
          break;
        case 'first-input':
          // console.log(entry);
          break;
        default:
        // console.log(entry);
      }
    }
  });
  observer.observe({ entryTypes: PerformanceObserver.supportedEntryTypes } as PerformanceObserverInit);

  setTimeout(() => {
    if (observer.takeRecords) observer.takeRecords(); // safari unsupported
    observer.disconnect();
    // console.log(JSON.stringify(performance, " ", " "))
    // console.log(performance);
  }, 10000);
}
