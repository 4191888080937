'use client';

import { Suspense, lazy } from 'react';

import { Loader } from '@mantine/core';

const BuildingMaintenance = lazy(() => import('~/features/aspi/Maintenance/Building'));

const BuildingMaintenanceView = () => {
  return (
    <Suspense fallback={<Loader color="indigo" size={50} />}>
      <div style={{ width: '100%', height: '100%', minWidth: '1280px' }}>
        <BuildingMaintenance tenantId={window.tenantId!} />
      </div>
    </Suspense>
  );
};

export default BuildingMaintenanceView;
