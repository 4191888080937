import { forwardRef, RefObject, useEffect, useState } from 'react';

import { clsx } from 'clsx';

import Clock from './Clock';
import { DaySquare } from './images/DaySquare';
import { MidnightSquare } from './images/MidnightSquare';
import { SunriseSquare } from './images/SunriseSquare';
import { SunsetSquare } from './images/SunsetSquare';

import styles from './ZoneSelectorPanel.module.css';

const scrollTo = (element: HTMLElement, to: number, duration: number) => {
  // jump to target if duration zero
  if (duration <= 0) {
    element.scrollTop = to;

    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  element.scrollTop += perTick;
  if (element.scrollTop === to) return;
  scrollTo(element, to, duration - 10);
};

type Props = {
  offset: number;
  step: number;
  fromValue?: string | null;
  workStartBlock: number;
  noonStartBlock: number;
};

const ZoneSelectorPanel = forwardRef<HTMLElement, Props>((props, ref) => {
  const { offset, step, workStartBlock, noonStartBlock } = props;
  const selectElem = (ref as RefObject<HTMLElement>).current!;
  const listElem = selectElem?.firstElementChild as HTMLUListElement;

  const [selected, setSelected] = useState<number>(0);

  const scrollToSelected = (index: number, duration: number) => {
    // move to selected item
    if (!listElem) {
      return;
    }
    if (index < 0) {
      index = 0;
    }
    const topOption = listElem.children[index] as HTMLElement;
    const to = topOption.offsetTop;
    scrollTo(selectElem, to, duration);
  };

  useEffect(() => {
    setTimeout(() => {
      setSelected(2);
    }, 0);
  }, []);

  useEffect(() => {
    switch (selected) {
      case 1:
        scrollToSelected(0, 120);
        break;
      case 2:
        scrollToSelected((workStartBlock - offset) / (60 / step), 120);
        break;
      case 3:
        scrollToSelected((noonStartBlock - offset) / (60 / step), 120);
        break;
      case 4:
        scrollToSelected(18, 120);
        break;
    }
  }, [selected]);

  return (
    <div className="w-12">
      <div className="flex flex-row items-start justify-center">
        <Clock time={props.fromValue ?? ''} />
      </div>
      <div className="flex flex-row items-start justify-center">
        <ul className="mt-8 flex flex-col items-start justify-start gap-2">
          <li>
            <SunriseSquare
              className={clsx([styles.item], { [styles.selected]: selected == 1 })}
              onClick={() => {
                setSelected(1);
              }}
            />
          </li>
          <li>
            <DaySquare
              className={clsx([styles.item], { [styles.selected]: selected == 2 })}
              onClick={() => {
                setSelected(2);
              }}
            />
          </li>
          <li>
            <SunsetSquare
              className={clsx([styles.item], { [styles.selected]: selected == 3 })}
              onClick={() => {
                setSelected(3);
              }}
            />
          </li>
          <li>
            <MidnightSquare
              className={clsx([styles.item], { [styles.selected]: selected == 4 })}
              onClick={() => {
                setSelected(4);
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
});
ZoneSelectorPanel.displayName = 'ZoneSelectorPanel';
export default ZoneSelectorPanel;
