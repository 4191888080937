import { DateTimeFormatter, LocalDate } from '@js-joda/core';

import { AxiosInstance } from 'axios';

export const dailyFetcher = (axios: AxiosInstance, tenantId: string, workDay: LocalDate, signal?: AbortSignal) =>
  axios.get(`/api/dashboard/v2/daily/${tenantId}/${workDay.format(DateTimeFormatter.ISO_LOCAL_DATE)}/list`, { signal });

export const monthlyFetcher = (axios: AxiosInstance, tenantId: string, targetYearmonth: string, signal?: AbortSignal) =>
  axios.get(`/api/dashboard/v2/monthly/${tenantId}/${targetYearmonth}/list`, { signal });

export const workTodayFetcher = (
  axios: AxiosInstance,
  tenantId: string,
  workDay: LocalDate,
  crewCode: string,
  signal?: AbortSignal
) =>
  axios.get(
    `/api/dashboard/v2/workdayCrew/${tenantId}/${workDay.format(DateTimeFormatter.ISO_LOCAL_DATE)}/${crewCode}/list`,
    { signal }
  );

export const operationLogFetcher = (axios: AxiosInstance, tenantId: string, signal?: AbortSignal) =>
  axios.get(`/api/dashboard/v2/logging/${tenantId}/list`, { signal });
