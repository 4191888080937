import { useEffect, useState } from 'react';

import ApplicationLog from '~/utils/ApplicationLog';

import { useAxios } from '~/components/common/providers';

import { watchdog } from '~/workers/watchdog/watchdog-client';

const LicenseView = () => {
  const axios = useAxios();
  useEffect(() => {
    void ApplicationLog(axios, 'license', 'enter', '');

    if (window.userId != null && window.userId.length > 0) {
      watchdog('license', 'ライセンス情報');
    }

    // ログイン直後用の落ち葉拾い
    const h = setInterval(() => {
      watchdog('license', 'ライセンス情報');
    }, 10000);

    return () => {
      clearInterval(h);
      void ApplicationLog(axios, 'license', 'exit', '');
    };
  }, []);

  const [role, setRole] = useState('');

  useEffect(() => {
    setRole(
      window.roles?.includes('r_sysadmin')
        ? 'r_sysadmin'
        : window.roles?.includes('r_admin')
          ? 'r_admin'
          : window.roles?.includes('r_user')
            ? 'r_user'
            : 'r_user'
    );
  }, [window.roles]);

  return <iframe src="/api/license/license.html" style={{ border: 'none', width: '100%', height: '100%' }} />;
};

export default LicenseView;
