import React from 'react';

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { recoilPersist } from 'recoil-persist';

import { decodeJwt } from '~/utils/decode';

import { AccessToken } from '~/domain/models/accessToken';

export type UserState = {
  userName: string;
  userCode: string;
  userId: string;
  email: string;
  tenantId: string;
  roles: Array<string>;
  permissions: Array<string>;
  sections: Array<string>;
  adminAuthority: boolean;
};

const { persistAtom } = recoilPersist({
  key: 'UserState',
  storage: typeof window === 'undefined' ? undefined : window.sessionStorage,
});

const UserRecoilState = atom<UserState>({
  key: 'UserState',
  default: {
    userName: '',
    userCode: '',
    userId: '',
    email: '',
    tenantId: '',
    roles: [],
    permissions: [],
    sections: [],
    adminAuthority: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useUserState = () => useRecoilValue(UserRecoilState);

export const useUserMutators = () => {
  const setState = useSetRecoilState(UserRecoilState);

  const setAccessToken = React.useCallback(
    (token: string) => {
      const decoded: AccessToken = decodeJwt(token);
      setState({
        userName: decoded.name,
        userCode: decoded.handle,
        userId: decoded.sub,
        email: decoded.email,
        tenantId: decoded.tenantId,
        roles: decoded.roles,
        permissions: decoded.permissions,
        sections: decoded.sections,
        adminAuthority: new Set(decoded.permissions).has('admin'),
      });
    },
    [setState]
  );

  const reset = React.useCallback(() => {
    setState({
      userName: '',
      userCode: '',
      userId: '',
      email: '',
      tenantId: '',
      roles: [],
      permissions: [],
      sections: [],
      adminAuthority: false,
    });
  }, [setState]);

  return { setAccessToken, reset };
};
