import React from 'react';

import classNames from 'clsx';

import styles from './index.module.css';

export type ButtonProps = {
  ref?: React.RefObject<HTMLButtonElement> | undefined;
} & JSX.IntrinsicElements['button'];

export const RegularRegistrationButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinRegistration, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RegularCancelButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinCancel, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};
export const RegularDangerButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinDanger, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RegularAppliedButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinApplied, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RegularOutputButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinOutput, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RegularNewButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinNew, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RegularAssistButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinAssist, styles.sizeRegular, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const HalfHeightRegistrationButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinRegistration, styles.sizeHalfHeight, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RawRegistrationButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinRegistration, styles.sizeNone9ptSemiBold, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RawCancelButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinCancel, styles.sizeNone9ptSemiBold, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RawDangerButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinDanger, styles.sizeNone9ptSemiBold, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RawOutputButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinOutput, styles.sizeNone9ptSemiBold, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RawAppliedButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinApplied, styles.sizeNone9ptSemiBold, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};

export const RawAssistButton: React.FC<ButtonProps> = props => {
  const { ref = undefined, className = '', disabled = false, onClick = () => {}, children, ...rest } = props;

  return (
    <button
      ref={ref}
      className={classNames(styles.skinAssist, styles.sizeNone9ptSemiBold, className)}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
};
