import React, { ReactNode, memo, useEffect, useState, useMemo } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { ActionIcon, AppShell, Burger, Drawer, Menu, ScrollArea, useMantineTheme } from '@mantine/core';
import { useClickOutside, useDisclosure, useFullscreen } from '@mantine/hooks';
import {
  IconAdjustments,
  IconArrowsMaximize,
  IconBellOff,
  IconBellRinging,
  IconCalendarStats,
  IconDatabase,
  IconFile,
  IconListCheck,
  IconLogout,
  IconNotes,
  IconPresentationAnalytics,
  IconUserCircle,
  IconWindowMaximize,
  IconWindowMinimize,
} from '@tabler/icons-react';

import { useQueryClient } from '@tanstack/react-query';

import classNames from 'clsx';

import { publish } from '~/utils/event';

import PosterIcon from '~/features/poster/common/PosterIcon';

import WebVitals from '~/features/WebVitals';

import { useAxios } from '~/components/common/providers';
import { LinksGroup } from '~/components/ui/aspi/NavbarLinksGroup/NavbarLinksGroup';

import classes from './Default.module.css';

const Header = memo(
  (props: {
    handler: [boolean, { readonly open: () => void; readonly close: () => void; readonly toggle: () => void }];
  }) => {
    const [menuOpened, menuHandlers] = props.handler;
    const { toggle, fullscreen } = useFullscreen();
    const [ringed, handlers] = useDisclosure(true, {
      onOpen: () => publish('ringOn'),
      onClose: () => publish('ringOff'),
    });

    return (
      <header className={classes.header}>
        <div className="flex flex-row items-center justify-between">
          <div className="flex cursor-pointer select-none flex-row items-center justify-start">
            <Burger
              opened={menuOpened}
              size="sm"
              color="gray"
              className="ml-2 mr-5 p-0"
              onClick={menuHandlers.toggle}
            />
            <Link to="/">
              <img
                // srcSet={`/api/images/${window.tenantId}/title.webp`}
                src={`/api/images/${window.tenantId}/title.png`}
                style={{ width: '300px' }}
                alt="home"
              />
            </Link>
          </div>

          <div className="flex flex-row items-center justify-start gap-2">
            <div className="hidden flex-row items-center justify-start sm:flex">
              <div className="mr-4 font-body text-[9pt] text-gray-800">{window.tenantName}</div>
              <IconUserCircle size={18} />
              <Menu shadow="md" trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <div className="cursor-pointer select-none font-body text-[9pt] text-gray-800">
                    {window.userName}（{window.roleName}）
                  </div>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={<IconLogout size={14} />}
                    onClick={() => {
                      const data = {
                        tenantId: window.tenantId,
                        tenantHandle: window.tenantHandle,
                        tenantName: window.tenantName,
                        userId: window.userId,
                        userCode: window.userCode,
                        userName: window.userName,
                      };
                      publish('logout', data);
                    }}>
                    <span className="cursor-pointer select-none font-body text-[9pt] text-gray-800">ログアウト</span>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
            <div className="block sm:hidden">
              <Menu shadow="md" trigger="hover" openDelay={100} closeDelay={400}>
                <Menu.Target>
                  <IconUserCircle size={18} />
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>{window.tenantName}</Menu.Label>
                  <Menu.Label>
                    {window.userName}（{window.roleName}）
                  </Menu.Label>
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<IconLogout size={14} />}
                    onClick={() => {
                      const data = {
                        tenantId: window.tenantId,
                        tenantHandle: window.tenantHandle,
                        tenantName: window.tenantName,
                        userId: window.userId,
                        userCode: window.userCode,
                        userName: window.userName,
                      };
                      publish('logout', data);
                    }}>
                    <span className="font-body text-[9pt] text-gray-800">ログアウト</span>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </div>
            <ActionIcon
              className="mb-1 mt-1"
              color="gray"
              variant="outline"
              aria-label="Ringging"
              onClick={handlers.toggle}>
              {ringed ? (
                <IconBellRinging color="blue" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              ) : (
                <IconBellOff color="red" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              )}
            </ActionIcon>
            <ActionIcon
              className="mb-1 mr-8 mt-1"
              color="gray"
              variant="outline"
              aria-label="Fullscreen"
              onClick={toggle}>
              {fullscreen ? (
                <IconWindowMinimize color="black" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              ) : (
                <IconWindowMaximize color="black" style={{ width: '70%', height: '70%' }} stroke={1.5} />
              )}
            </ActionIcon>
          </div>
        </div>
      </header>
    );
  }
);

// eslint-disable-next-line react/display-name
const Footer = memo(() => {
  const axios = useAxios();
  const [specVersion, setSpecVersion] = useState<string>('');
  const [implVersion, setImplVersion] = useState<string>('');

  useEffect(() => {
    void axios.get<string>('/api/version/implementation', { cache: false }).then(res => {
      window.implVersion = res.data;
      setImplVersion(res.data);
    });

    void axios.get<string>('/api/version/specification', { cache: false }).then(res => {
      window.specVersion = res.data;
      setSpecVersion(res.data);
    });
  }, []);

  return (
    <footer className={classes.footer}>
      <div className="flex flex-row justify-between pb-4 pr-4">
        <div className="font-body text-[9pt] text-gray-900">&copy; AQIT Corporation.</div>
        <div className="font-body text-[9pt] text-gray-900" title={implVersion}>
          ASPI Version {specVersion}
        </div>
      </div>
    </footer>
  );
});

// eslint-disable-next-line react/display-name
const Navbar = memo(
  (props: {
    links: ReactNode;
    handler: [boolean, { readonly open: () => void; readonly close: () => void; readonly toggle: () => void }];
  }) => {
    const [menuOpened, menuHandlers] = props.handler;

    const queryClient = useQueryClient();

    useEffect(() => {
      void queryClient.cancelQueries();
      void queryClient.clear();
    }, [menuOpened]);

    return (
      <Drawer
        zIndex={1000}
        closeOnClickOutside={true}
        closeOnEscape={true}
        withCloseButton={false}
        withOverlay={true}
        position="left"
        size={250}
        overlayProps={{ backgroundOpacity: 0.25, blur: 2 }}
        transitionProps={{ transition: 'slide-right', duration: 400, timingFunction: 'ease' }}
        opened={menuOpened}
        classNames={{ content: 'relative top-10' }}
        onClose={menuHandlers.close}>
        <nav className={classes.navbar}>
          <ScrollArea className={classes.links}>
            <div className={classes.linksInner}>{props.links}</div>
          </ScrollArea>
        </nav>
      </Drawer>
    );
  }
);

export default () => {
  // const theme = useMantineTheme();
  const [menuOpened, menuHandlers] = useDisclosure(false);
  const { toggle, fullscreen } = useFullscreen();

  const roles = new Set(window.roles);

  const rSysAdmin = roles.has('r_sysadmin');
  const rAdmin = roles.has('r_admin') && !rSysAdmin;
  const rUser = roles.has('r_user') && !rAdmin && !rSysAdmin;

  const showVehicle = window.settings.tenant.showVehicle;

  const [dropdown, setDropdown] = useState<any | null>(null);
  const [control, setControl] = useState<any | null>(null);
  useClickOutside(() => menuHandlers.close(), ['mouseup', 'touchend'], [control, dropdown]);

  const menuDataSysAdmin = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            {
              label: '契約情報管理',
              icon: IconNotes,
              initiallyOpened: false,
              links: [
                { label: '契約データ', link: '/maintenance/contract' },
                window.settings.tenant.yearlyContractEnabled == true
                  ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                  : null,
              ].filter(f => f != null),
            },
            {
              label: '作業計画管理',
              icon: IconCalendarStats,
              links: [
                { label: '稼働予定', link: '/planning/crew' },
                { label: '計画作成用データ確認', link: '/planning/work' },
                { label: '作業計画確認', link: '/planning/confirmation' },
              ],
            },
            window.settings.tenant.poster && window.permissions.find(f => 'p_poster') != null
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview', disabled: false },
                    { label: 'チラシフォーム管理', link: '/poster/template', disabled: false },
                    { label: 'お知らせ状況管理', link: '/poster/project', disabled: false },
                  ],
                }
              : null,
            window.settings.tenant.project && window.permissions.find(f => 'p_project') != null
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true },
            {
              label: 'マスタ管理',
              icon: IconDatabase,
              links: [
                { label: '物件マスタ', link: '/maintenance/building', disabled: false },
                { label: '取引先マスタ', link: '/maintenance/customer', disabled: false },
                showVehicle ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false } : null,
                { label: 'ユーザマスタ', link: '/maintenance/user', disabled: false },
                { label: '部門マスタ', link: '/maintenance/section', disabled: false },
                { label: '作業マスタ', link: '/maintenance/operation', disabled: false },
                { label: '作業分類マスタ', link: '/maintenance/operationCategory', disabled: false },
                { label: '資格・技能マスタ', link: '/maintenance/qualification', disabled: false },
              ].filter(f => f != null),
            },
            {
              label: 'システム管理',
              icon: IconAdjustments,
              links: [
                { label: 'システム設定', link: '/config', disabled: false },
                { label: 'ログ管理', link: '/applicationLogging', disabled: false },
                { label: 'ログイン中ユーザー一覧', link: '/sessionMonitor', disabled: false },
                { label: 'ライセンス情報', link: '/license', disabled: false },
                // { label: 'テスト', link: '/test/timepicker', disabled: false },
              ],
            },
          ]
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const menuDataAdmin = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            {
              label: '契約情報管理',
              icon: IconNotes,
              initiallyOpened: true,
              links: [
                { label: '契約データ', link: '/maintenance/contract' },
                window.settings.tenant.yearlyContractEnabled == true
                  ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                  : null,
              ].filter(f => f != null),
            },
            {
              label: '作業計画管理',
              icon: IconCalendarStats,
              links: [
                { label: '稼働予定', link: '/planning/crew' },
                { label: '計画作成用データ確認', link: '/planning/work' },
                { label: '作業計画確認', link: '/planning/confirmation' },
              ],
            },
            window.settings.tenant.poster && window.permissions.find(f => 'p_poster') != null
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview' },
                    { label: 'チラシフォーム管理', link: '/poster/template' },
                    { label: 'お知らせ状況管理', link: '/poster/project' },
                  ],
                }
              : null,
            window.settings.tenant.project && window.permissions.find(f => 'p_project') != null
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true },
            {
              label: 'マスタ管理',
              icon: IconDatabase,
              links: [
                { label: '物件管理', link: '/maintenance/building' },
                { label: '取引先管理', link: '/maintenance/customer' },
                showVehicle ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false } : null,
              ].filter(f => f != null),
            },
          ]
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const menuDataUser = useMemo(
    () =>
      (window.settings != null && window.permissions != null
        ? [
            {
              label: '契約情報管理',
              icon: IconNotes,
              initiallyOpened: true,
              links: [
                { label: '契約データ', link: '/maintenance/contract' },
                window.settings.tenant.yearlyContractEnabled == true
                  ? { label: '物件別契約情報管理', link: '/yearlyContract' }
                  : null,
              ].filter(f => f != null),
            },
            {
              label: '作業計画管理',
              icon: IconCalendarStats,
              initiallyOpened: true,
              links: [{ label: '作業計画確認', link: '/planning/confirmation', disabled: false }],
            },
            window.settings.tenant.poster && window.permissions.find(f => 'p_poster') != null
              ? {
                  label: 'お知らせ管理',
                  icon: PosterIcon,
                  links: [
                    { label: 'チラシレイアウト確認', link: '/poster/preview', disabled: false },
                    { label: 'チラシフォーム管理', link: '/poster/template', disabled: false },
                    { label: 'お知らせ状況管理', link: '/poster/project', disabled: false },
                  ],
                }
              : null,
            window.settings.tenant.project && window.permissions.find(f => 'p_project') != null
              ? {
                  label: '工程管理',
                  icon: IconListCheck,
                  links: [{ label: '工程一覧', link: '/project' }],
                }
              : null,
            { label: 'データ分析', icon: IconPresentationAnalytics, disabled: true },
            {
              label: 'マスタ管理',
              icon: IconDatabase,
              links: [
                { label: '物件管理', link: '/maintenance/building' },
                { label: '取引先管理', link: '/maintenance/customer' },
                showVehicle ? { label: '車両管理マスタ', link: '/maintenance/vehicle', disabled: false } : null,
              ].filter(f => f != null),
            },
          ]
        : []
      ).filter(f => f != null),
    [window.settings, window.permissions]
  );

  const links = useMemo(
    () => (
      <>
        <div className={classNames({ block: rSysAdmin, hidden: !rSysAdmin })}>
          {menuDataSysAdmin.map(item => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LinksGroup
              {...item}
              key={item?.label ?? ''}
              onClick={e => {
                menuHandlers.close();
                //setTimeout(menuHandlers.close, 0);
              }}
            />
          ))}
        </div>
        <div className={classNames({ block: rAdmin, hidden: !rAdmin })}>
          {menuDataAdmin.map(item => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LinksGroup
              {...item}
              key={item?.label ?? ''}
              onClick={e => {
                menuHandlers.close();
                // setTimeout(menuHandlers.close, 0);
              }}
            />
          ))}
        </div>
        <div className={classNames({ block: rUser, hidden: !rUser })}>
          {menuDataUser.map(item => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <LinksGroup
              {...item}
              key={item?.label ?? ''}
              onClick={e => {
                menuHandlers.close();
                //setTimeout(menuHandlers.close, 0);
              }}
            />
          ))}
        </div>
      </>
    ),
    [rSysAdmin, rAdmin, rUser, menuDataSysAdmin, menuDataAdmin, menuDataUser]
  );

  return (
    <div className={classes.main}>
      <Header handler={[menuOpened, menuHandlers]} />
      <Navbar links={links} handler={[menuOpened, menuHandlers]} />
      <main className="p-2" style={{ height: 'calc( 100vh - 52px' }}>
        <Outlet />
      </main>
      <Footer />
      <WebVitals />
    </div>
  );
};
