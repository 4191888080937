export const groupBy = <K, V>(array: readonly V[], getKey: (cur: V, idx: number, src: readonly V[]) => K): [K, V[]][] =>
  Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);

      return map;
    }, new Map<K, V[]>())
  );

declare global {
  interface Array<T> {
    groupBy<K>(getKey: (cur: T, idx: number, src: readonly T[]) => K): [K, T[]][];
  }
}

Array.prototype.groupBy = function <K, V>(getKey: (cur: V, idx: number, src: readonly V[]) => K): [K, V[]][] {
  return groupBy(this, getKey);
};
