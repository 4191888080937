import { useState } from 'react';

import { showNotification } from '@mantine/notifications';

import TimePicker from '~/features/aspi/Complement/components/TimePicker2/TimePicker';

const Index = () => {
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  return (
    <div className="flex flex-row items-center justify-center">
      <TimePicker
        className="h-6 w-[3.5rem] rounded border border-gray-500 px-2 text-[8pt]"
        startTimeOfDay="05:00"
        workStartTime="09:00"
        minutesPerBlock={15}
        disabled={false}
        value={from}
        min={'09:45'}
        max={to}
        isEnd={false}
        onChange={value => {
          setFrom(value);
          showNotification({
            title: 'value',
            message: value,
            color: 'green',
            autoClose: 1000,
          });
        }}
      />
      〜
      <TimePicker
        className="h-6 w-[3.5rem] rounded border border-gray-500 px-2 text-[8pt]"
        startTimeOfDay="05:00"
        workStartTime="09:00"
        minutesPerBlock={15}
        disabled={false}
        value={to}
        min={from}
        max={'20:15'}
        // length={1.0}
        isEnd={true}
        onChange={value => {
          setTo(value);
          showNotification({
            title: 'value',
            message: value,
            color: 'red',
            autoClose: 1000,
          });
        }}
      />
    </div>
  );
};

export default Index;
