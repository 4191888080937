import React from 'react';

import { showNotification } from '@mantine/notifications';
import { IconInfoCircle, IconX } from '@tabler/icons-react';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { publish, subscribe } from '~/utils/event';

import { ApplicationLoggingMessage, MessageWrapper, SetupMessage } from '~/workers/session/message';

const { watchdogWorker } = window;

declare global {
  interface Window {
    sessionWorker: Worker;
  }
}

const uuidv4Math = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16).replace('-', '');
  });

export const registerSession = async () => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  if (!localStorage.getItem('client-id')) {
    localStorage.setItem('client-id', uuidv4Math());
  }
  if (!localStorage.getItem('fingerprint')) {
    localStorage.setItem('fingerprint', result.visitorId);
  }

  const clientId = localStorage.getItem('client-id');
  const fingerprint = localStorage.getItem('fingerprint');
  const { instanceId } = window;

  const session = new Worker(new URL('./worker.ts', import.meta.url), {
    /* normal Worker options */
  });
  window.watchdogWorker = session;

  session.onmessage = (event: MessageEvent) => {
    // console.log(event.data);
    const data = event.data as MessageWrapper<any>;
    if (data.messageType === 'kickOut') {
      publish('kickOut', event.data.message as MessageWrapper<any>);
      showNotification({
        id: 'kickOut',
        autoClose: false,
        title: '強制ログアウト',
        message: event.data.message.message ?? 'システム管理者の操作により強制ログアウトしました。',
        icon: <IconX />,
        color: 'red',
      });
    } else if (data.messageType === 'sendMessage') {
      publish('sendMessage', event.data.message as MessageWrapper<any>);
      if (event.data.message.message != null && event.data.message.message.length > 0) {
        showNotification({
          id: 'sendMessage',
          autoClose: false,
          title: 'メッセージ',
          message: event.data.message.message ?? '',
          icon: <IconInfoCircle />,
          color: 'blue',
        });
      }
    } else {
      publish('receive', event.data as MessageWrapper<any>);
    }
  };

  subscribe('send', (evt: Event) => {
    const msg = (evt as CustomEvent).detail as MessageWrapper<any>;
    // console.log(msg);
    if (msg.message != null) {
      msg.message = {} as any;
    }
    msg.message.tenantId = window.tenantId;
    msg.message.userId = window.userId;
    // msg.message.userCode = window.userCode;
    // msg.message.userName = window.userName;
    session.postMessage(msg);
  });

  // subscribe('login', (evt: Event) => {
  //   const token = (evt as CustomEvent<string>).detail;
  //   const decoded: AccessToken = decodeJwt(token);
  //   session.postMessage({messageType: 'login', tenantId: decoded.tenantId, userId: decoded.sub, message: decoded.name});
  //   const log = {
  //     messageType:'applicationLogging',
  //     functionHandle: 'login',
  //     functionName: 'ログイン',
  //     actionHandle: 'login',
  //     actionName: 'ログイン',
  //     message: `ログイン ${decoded.tenantHandle} ${decoded.handle} ${decoded.name}`,
  //   } as ApplicationLoggingMessage
  //   session.postMessage(log);
  // });
  //
  // subscribe('logout', (evt: Event) => {
  //   // @ts-ignore
  //   const { tenantHandle, userCode, crewName} = (evt as CustomEvent<string>).detail;
  //   session.postMessage({messageType: 'logout', message: ""});
  //   const log = {
  //     messageType:'applicationLogging',
  //     functionHandle: 'logout',
  //     functionName: 'ログアウト',
  //     actionHandle: 'logout',
  //     actionName: `ログアウト ${tenantHandle} ${userCode} ${crewName}`,
  //     message: `ログアウト`,
  //   } as ApplicationLoggingMessage
  //   session.postMessage(log);
  // })

  subscribe('applicationLogging', async (evt: Event) => {
    const msg = (evt as CustomEvent<ApplicationLoggingMessage>).detail;
    msg.messageType = 'applicationLogging';
    if (msg.actionHandle === 'enter' || msg.actionHandle === 'exit') {
      msg.tenantId = window.tenantId ?? '';
      msg.userId = window.userId ?? '';
      // msg.userCode = window.userCode ?? '';
      // msg.userName = window.userName ?? '';
      session.postMessage(msg);
    }
  });

  publish('send', { messageType: 'setup', message: { clientId, fingerprint, instanceId } as SetupMessage });
};
