import { memo, SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 742 742" {...props}>
    <path
      fill="#ade7ff"
      d="M23 417V78a50 50 0 0 1 49-52h600c25 0 46 20 48 42l1 13-1 336a4019 4019 0 0 1-154 0l70-41-71-41v82l-29-1c5-21 6-43 3-65-6-44-27-80-61-108-39-32-83-45-133-37-64 10-109 46-135 106-14 34-16 69-7 105H23m512-221-55 14 57 58 21-79-23 7M161 408l14 8v-83l-71 41 57 34m208-296-40 69h82l-2-4-34-58-6-7m-126 93-60-16 21 78 57-57-18-5Z"
    />
    <path
      fill="#4273c6"
      d="M536 417a8162 8162 0 0 0 30 0h154l1 257c0 28-22 49-50 49H75a49 49 0 0 1-52-52V418l118-1h395m-168 65h89c14 0 23-15 17-28-4-8-11-11-20-11H115c-13 1-22 15-16 27 3 9 11 12 20 12h249m-87 51c4 10 10 14 20 14h290c11 0 20-8 20-19s-9-20-20-20H300c-13 0-22 11-19 25m-13 79h244c10-1 18-9 18-19 0-11-8-20-21-20H261c-13 1-22 15-16 27 4 10 12 13 23 12m356-170H519c-4 0-9 1-12 3-8 4-12 14-9 22 2 9 10 15 19 15h109l6-1c10-2 16-11 15-22-1-10-10-17-23-17m-437 67h-23c-5 1-9 2-13 5-6 4-9 13-6 21s10 13 19 13h72c12 0 21-8 21-20 0-11-9-19-21-19h-49Z"
    />
    <path
      fill="#ff7373"
      d="m536 416-3 1H204c-10-36-8-71 6-105 26-60 71-96 135-106 50-8 94 5 133 37 34 28 55 64 61 108 3 22 2 44-3 65Z"
    />
    <path fill="#fe7576" d="m535 195 23-6-21 79-57-58 55-15Z" />
    <path
      fill="#ff7474"
      d="m161 408-57-34 71-41v83l-14-8Zm208-297 6 8 34 58 2 4h-82l40-70Zm197 306h-1v-82l71 41-70 41Z"
    />
    <path fill="#ff7475" d="m243 205 18 5-57 57-21-78 60 16Z" />
    <path
      fill="#ade7ff"
      d="M367 482H119c-9 0-17-3-20-12-6-12 3-26 16-27h339c9 0 16 3 20 11 6 13-3 28-17 28h-90Zm-86 51c-3-14 6-25 19-25h291c11 0 20 9 20 20s-9 19-20 19H301c-10 0-16-4-20-14Zm-14 79c-10 1-18-2-22-12a19 19 0 0 1 19-27h245c13 0 21 9 21 20 0 10-8 18-18 19H267Zm358-170c12 0 21 7 22 17a20 20 0 0 1-21 23H517c-9 0-17-6-19-15-3-8 1-18 9-22 3-2 8-3 12-3h106Zm-438 67h49c12 0 21 8 21 19 0 12-9 20-21 20h-72c-9 0-16-5-19-13s0-17 6-21c4-3 8-4 13-5h23Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export { Memo as SunriseSquare };
